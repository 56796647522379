<template>
  <v-list
    :class="`language-switcher ${
      floating ? 'language-switcher--floating' : ''
    } py-0 px-0`"
    expand
    dense
    nav
    elevation="0"
  >
    <v-list-group v-model="languageExpand">
      <template #activator>
        <v-list-item-title>{{ $t(language.title).substr(0, 3) }}</v-list-item-title>
      </template>
      <template #appendIcon>
        <v-img max-width="20px" :src="require('@/assets/drop-down.svg')" />
      </template>
      <v-list-item
        v-for="(item, i) in exceptSelected"
        :key="i"
        link
        @click.prevent="switchLanguage(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(item.title) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  props: {
    floating: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  computed: {
    exceptSelected: function () {
      const available = [];
      for (const i in this.languages) {
        if (this.languages[i].short !== this.$i18n.locale) {
          available.push(this.languages[i]);
        }
      }
      return available;
    },
  },
  watch: {
    language: function () {
      this.languageExpand = false;
    },
  },
  data: () => ({
    languageExpand: false,
    language: null,
    languages: [
      {
        short: "es",
        title: "language.spanish",
      },
      {
        short: "en",
        title: "language.english",
      },
    ],
  }),
  async created() {
    for (const i in this.languages) {
      if (this.languages[i].short === this.$i18n.locale) {
        this.language = this.languages[i];
      }
    }
  },
  methods: {
    switchLanguage(item) {
      this.language = item;
      this.$store.dispatch("language/setLanguage", item.short);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-switcher {
  overflow: hidden;
  .v-list-item__title {
    color: #000000;
    text-transform: uppercase;
    font-size: 18px;
  }
  &:not(.language-switcher--floating) {
    display: inline-block;
  }
  &.language-switcher--floating {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .v-list-group {
    .v-list-group__header {
      > .v-list-item__title {
        margin-right: -15px;
      }
    }
    .v-image {
      transition: all 1s ease;
    }
    .v-list-group--active {
      .v-image {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
